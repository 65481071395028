<script setup lang="ts">
import { type ImgHTMLAttributes } from 'nuxt/dist/app/compat/capi'
import { refAutoReset } from '@vueuse/core'
const log = createLog('app:qrhunt:content')

const { $qrHuntRouter, $headerBackTo, $toast, $t, $e } = useNuxtApp()
$headerBackTo()

const contentStore = useContentStore()
const contentUid = toValue(contentStore.uid)
const qrHuntStore = useQRHuntStore()

const isBuisy = refAutoReset(false, UI_UNLOCK_TIMEOUT)
const hasError = ref(false)
const errorMessage = ref('')

contentStore.actionAcknowledgeContent(contentUid!)

const nextButtonRoute = ref<QRHuntRoute>('scan')
onWSMessage<RouteResponse>(RouteResponseSchema, (data) => {
    isBuisy.value = false
    log.debug(data)

    if (data.content.checkpoints.every((checkpoint) => checkpoint.finished)) {
        const finishedRoute: QRHuntRouteNormalized = {
            name: 'finished',
            params: qrHuntStore.transformRoute(data.content) as TransformedRoute,
        }

        nextButtonRoute.value = finishedRoute
        return
    }
})

onWSMessage<WsError>(ErrorSchema, (data) => {
    isBuisy.value = false
    hasError.value = true
    errorMessage.value = $e(data.content.errorType)

    log.error(errorMessage.value)
})

onMounted(() => {
    setTimeout(() => {
        popConfetti()
    }, 1000)
})
</script>

<template>
    <div class="page-checkpoint-fact">
        <div class="page-checkpoint-fact__content">
            <template
                v-for="(component, index) in contentStore.getContent"
                :key="index"
            >
                <img
                    v-if="(component as ComponentBuilderImageComponent).type === 'image'"
                    v-bind="component as ComponentBuilderImageComponent as ImgHTMLAttributes"
                    class="media"
                />
                <RichText
                    v-else-if="(component as ComponentBuilderTextComponent).type === 'text'"
                    v-bind="component as ComponentBuilderTextComponent"
                    :text="(component as ComponentBuilderTextComponent).html"
                />
            </template>
        </div>

        <ButtonGroup class="page-checkpoint-fact__buttons">
            <AppButton
                fluid
                :disabled="!contentUid || isBuisy"
                @click="!isBuisy && $qrHuntRouter.push(nextButtonRoute)"
            >
                {{ $t('page.routeFact.buttonLabel.next') }}
            </AppButton>
        </ButtonGroup>

        <Modal v-model:open="hasError">
            <div class="page-checkpoint-fact__error">
                <Icon
                    name="stop-48x"
                    fluid
                    circle="#e59eb2"
                    class="page-checkpoint-fact__error-icon"
                />
                <RichText
                    :text="errorMessage"
                    class="rich-text--compact"
                />
            </div>
        </Modal>
    </div>
</template>

<style lang="scss">
.page-checkpoint-fact {
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(26px, 1fr) auto;
    grid-template-areas:
        'content'
        '.      '
        'buttons';

    &__content {
        grid-area: content;

        .media {
            max-width: 100%;
            aspect-ratio: 1 / 1;
            height: auto;
            width: 100%;
        }
    }

    &__media {
        grid-area: media;
        max-width: 100%;
        aspect-ratio: 1 / 1;
        height: auto;
        width: 100%;
    }

    &__error {
        min-height: 100%;
        padding: 102px 24px 36px;

        &-icon {
            color: v(color-text);
            width: min(100%, 80px);
            margin-bottom: 48px;
        }
    }

    &__text {
        grid-area: text;
    }

    &__buttons {
        grid-area: buttons;
    }
}
</style>
