import { default as confetti } from 'canvas-confetti'

const count = 200

const defaults = {
    origin: { y: 0.7 },
    colors: [
        '00828c',
        'cc99cc',
        'bf0d3e',
        'ea7600',
        '006f57',
        'e0cbc1',
        'f9bf1c',
        'c8ca4e',
        'f191b9',
        '00a2c9',
    ],
}

function fire(particleRatio: number, opts: Object) {
    confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
    })
}

export function popConfetti() {
    fire(0.25, {
        spread: 26,
        startVelocity: 55,
    })

    fire(0.2, {
        spread: 60,
    })

    fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
    })

    fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
    })

    fire(0.1, {
        spread: 120,
        startVelocity: 45,
    })
}
